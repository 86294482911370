<template>
  <div
  class="w-full"
  >

  <vx-card>
        <div id="data-list-list-view" class="data-list-container">
          <vs-table
            ref="table"
            :max-items="itemsPerPage"
            :data="Model"
          >
            <template slot="thead">
              <vs-th >{{ $t("Patient") }}</vs-th>
              <vs-th ></vs-th>
            </template>

            <template slot-scope="{ data }">
              <tbody :data="item" :key="indextr" v-for="(item, indextr) in data">
                <vs-tr class="cursor-pointer hover:text-primary">
                  <vs-td>
                    <b-col  class="product-name font-medium truncate">
                      
                      <imageLazy
              :imageStyle="{ height: '40px', width: '40px' }"
              :imageClass="'rounded-full'"
              :src="baseURL + item.PatientImage"
              placeHolderType="patient"
            />
            {{ item.PatientName }}
{{ item.CategoryID }}
                    </b-col>
                  </vs-td>
                  <vs-td>
                    <div
              v-if="item.CategoryID == 1"
              @click="
                navigateToPage('/doctor/DoctorSessionDetails/' + item.ReservedSessionID)
              "
            >
              <u class="product-name font-medium" style="text-decoration-line: underline;  ">
                {{ $t("hasReservationSessionon") }} {{ item.Date.split("T")[0] }}
                {{ item.Date.split("T")[1] }}
              </u>
            </div>

            <div
              v-if="item.CategoryID == 6"
              @click="
                navigateToPage('/doctor/DoctorSessionDetails/' + item.ReservedSessionID)
              "
            >
              <u class="product-name font-medium">
                {{ $t("PleaseAddPrescription") }}
              </u>
            </div>

            <!-- if patient has Reservation Surgeries-->
            <div
              v-if="item.CategoryID == 2"
              @click="
                navigateToPage(
                  '/TreatmentsProfile/' +
                    item.HospitalSurgeryID +
                    '/0/' +
                    item.ReservedSurgeryID
                )
              "
            >
              <u class="product-name font-medium" style="text-decoration-line: underline;  ">
                {{ $t("hasReservationSurgeries") }}
              </u>
            </div>

            <!-- if patient has History Analysis-->
            <div
              v-else-if="item.CategoryID == 3"
              @click="
                item.ReservedSurgeryID
                  ? navigateToPage(
                      '/TreatmentsProfile/' +
                        item.HospitalSurgeryID +
                        '/0/' +
                        item.ReservedSurgeryID
                    )
                  : navigateToPage(
                      '/doctor/DoctorSessionDetails/' + item.ReservedSessionID
                    )
              "
            >
              <u class="product-name font-medium" style="text-decoration-line: underline;  ">
                {{ $t("downloadNote") }} {{ item.ReportTypeName }}
                {{ $t("Documentdoesntdownloadyet") }}
              </u>
            </div>

            <!-- if patient has History Report-->
            <div
              v-else-if="item.CategoryID == 4"
              @click="
                item.ReservedSurgeryID
                  ? navigateToPage(
                      '/TreatmentsProfile/' +
                        item.HospitalSurgeryID +
                        '/0/' +
                        item.ReservedSurgeryID
                    )
                  : navigateToPage(
                      '/doctor/DoctorSessionDetails/' + item.ReservedSessionID
                    )
              "
            >
              <u class="product-name font-medium" style="text-decoration-line: underline;  ">
                {{ $t("downloadNote") }} {{ item.ReportTypeName }}
                {{ $t("Documentdoesntdownloadyet") }}
              </u>
            </div>

            <!-- if patient has History Scan-->
            <div
              v-else-if="item.CategoryID == 5"
              @click="
                item.ReservedSurgeryID
                  ? navigateToPage(
                      '/TreatmentsProfile/' +
                        item.HospitalSurgeryID +
                        '/0/' +
                        item.ReservedSurgeryID
                    )
                  : navigateToPage(
                      '/doctor/DoctorSessionDetails/' + item.ReservedSessionID
                    )
              "
            >
              <u class="product-name font-medium" style="text-decoration-line: underline;  ">
                {{ $t("downloadNote") }} {{ item.ReportTypeName }}
                {{ $t("Documentdoesntdownloadyet") }}
              </u>
            </div>
                  </vs-td>
                
                
                </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </div>
      </vx-card>
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <b-button type="border" color="danger" @click="$router.go(-1)">{{
              $t("Back")
            }}</b-button>
          </div>
        </div>
      </div>
  </div>
</template>


<script>
import imageLazy from "@/components/image_lazy.vue";
import { domain } from "@/gloabelConstant.js";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import { BAvatar, BBadge, BButton, BCard, BCol, BDropdown, BDropdownItem, BFormInput, BLink, BMedia, BPagination, BRow, BTable } from 'bootstrap-vue';

export default {
  components: {
    imageLazy,
    BAvatar, BBadge, BButton, BCard, BCol, BDropdown, BDropdownItem, BFormInput, BLink, BMedia, BPagination, BRow, BTable,
  },
  data() {
    return {
      EnglishLan: false,
      baseURL: domain,
      Model: [],
      itemsPerPage: 10,
      paginationPageNumber:1,
      paginationMaxNumber : 5,
    };
  },
  computed: {
    patientReservation() {
      return this.$store.state.DoctorList.pendingTaskList;
    },
  },
  methods: {
    navigateToPage(page) {
      this.$router.push(page);
    },
    back() {
      this.$router.go(-1);
    },
    searchData() {
      debugger;
      if (this.$route.params.ID) {
        var id = this.$route.params.ID;
        this.$store
          .dispatch("DoctorList/GetPendingDoctorTaskList", id)
          .then((res) => {
            if (res.status == 200) {
              debugger;
              this.Model = res.data.Data;
              if (this.Model == null || this.Model.length == 0) {
                this.$vs.notify({
                  title: this.$t("NoData"),
                  text: this.$t("NoDataToshow"),
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "warning",
                });
              }
            }
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      }
      var x = this.Model.length;
      debugger;
    },
  },
  created() {
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" || localStorage.getItem("SaveLang") == null
        ? true
        : false;

    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    this.searchData();
  },
};
</script>
<style>
.linkClass {
  text-decoration: underline;
  font: normal normal normal 15px/22px Futura PT;
  letter-spacing: 0px;

  text-transform: capitalize;
  opacity: 1;
}
.taskDoctor {
  background-color: transparent;
}
.borderClass {
  width: 100%;
  height: 2px;
  border: 1px solid #e7e7e7;
  opacity: 1;
}
#PendingDTask .vs-button {
  display: inline;
  background-color: transparent !important;
  color: gray;
  width: 5rem !important;
}
</style>


<style lang="scss">

#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 10px;
          width: fit-content;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}

</style>