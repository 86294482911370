var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full"},[_c('vx-card',[_c('div',{staticClass:"data-list-container",attrs:{"id":"data-list-list-view"}},[_c('vs-table',{ref:"table",attrs:{"max-items":_vm.itemsPerPage,"data":_vm.Model},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(item,indextr){return _c('tbody',{key:indextr,attrs:{"data":item}},[_c('vs-tr',{staticClass:"cursor-pointer hover:text-primary"},[_c('vs-td',[_c('b-col',{staticClass:"product-name font-medium truncate"},[_c('imageLazy',{attrs:{"imageStyle":{ height: '40px', width: '40px' },"imageClass":'rounded-full',"src":_vm.baseURL + item.PatientImage,"placeHolderType":"patient"}}),_vm._v(" "+_vm._s(item.PatientName)+" "+_vm._s(item.CategoryID)+" ")],1)],1),_c('vs-td',[(item.CategoryID == 1)?_c('div',{on:{"click":function($event){return _vm.navigateToPage('/doctor/DoctorSessionDetails/' + item.ReservedSessionID)}}},[_c('u',{staticClass:"product-name font-medium",staticStyle:{"text-decoration-line":"underline"}},[_vm._v(" "+_vm._s(_vm.$t("hasReservationSessionon"))+" "+_vm._s(item.Date.split("T")[0])+" "+_vm._s(item.Date.split("T")[1])+" ")])]):_vm._e(),(item.CategoryID == 6)?_c('div',{on:{"click":function($event){return _vm.navigateToPage('/doctor/DoctorSessionDetails/' + item.ReservedSessionID)}}},[_c('u',{staticClass:"product-name font-medium"},[_vm._v(" "+_vm._s(_vm.$t("PleaseAddPrescription"))+" ")])]):_vm._e(),(item.CategoryID == 2)?_c('div',{on:{"click":function($event){return _vm.navigateToPage(
                  '/TreatmentsProfile/' +
                    item.HospitalSurgeryID +
                    '/0/' +
                    item.ReservedSurgeryID
                )}}},[_c('u',{staticClass:"product-name font-medium",staticStyle:{"text-decoration-line":"underline"}},[_vm._v(" "+_vm._s(_vm.$t("hasReservationSurgeries"))+" ")])]):(item.CategoryID == 3)?_c('div',{on:{"click":function($event){item.ReservedSurgeryID
                  ? _vm.navigateToPage(
                      '/TreatmentsProfile/' +
                        item.HospitalSurgeryID +
                        '/0/' +
                        item.ReservedSurgeryID
                    )
                  : _vm.navigateToPage(
                      '/doctor/DoctorSessionDetails/' + item.ReservedSessionID
                    )}}},[_c('u',{staticClass:"product-name font-medium",staticStyle:{"text-decoration-line":"underline"}},[_vm._v(" "+_vm._s(_vm.$t("downloadNote"))+" "+_vm._s(item.ReportTypeName)+" "+_vm._s(_vm.$t("Documentdoesntdownloadyet"))+" ")])]):(item.CategoryID == 4)?_c('div',{on:{"click":function($event){item.ReservedSurgeryID
                  ? _vm.navigateToPage(
                      '/TreatmentsProfile/' +
                        item.HospitalSurgeryID +
                        '/0/' +
                        item.ReservedSurgeryID
                    )
                  : _vm.navigateToPage(
                      '/doctor/DoctorSessionDetails/' + item.ReservedSessionID
                    )}}},[_c('u',{staticClass:"product-name font-medium",staticStyle:{"text-decoration-line":"underline"}},[_vm._v(" "+_vm._s(_vm.$t("downloadNote"))+" "+_vm._s(item.ReportTypeName)+" "+_vm._s(_vm.$t("Documentdoesntdownloadyet"))+" ")])]):(item.CategoryID == 5)?_c('div',{on:{"click":function($event){item.ReservedSurgeryID
                  ? _vm.navigateToPage(
                      '/TreatmentsProfile/' +
                        item.HospitalSurgeryID +
                        '/0/' +
                        item.ReservedSurgeryID
                    )
                  : _vm.navigateToPage(
                      '/doctor/DoctorSessionDetails/' + item.ReservedSessionID
                    )}}},[_c('u',{staticClass:"product-name font-medium",staticStyle:{"text-decoration-line":"underline"}},[_vm._v(" "+_vm._s(_vm.$t("downloadNote"))+" "+_vm._s(item.ReportTypeName)+" "+_vm._s(_vm.$t("Documentdoesntdownloadyet"))+" ")])]):_vm._e()])],1)],1)})}}])},[_c('template',{slot:"thead"},[_c('vs-th',[_vm._v(_vm._s(_vm.$t("Patient")))]),_c('vs-th')],1)],2)],1)]),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full"},[_c('div',{staticClass:"mt-8 flex flex-wrap items-center justify-end"},[_c('b-button',{attrs:{"type":"border","color":"danger"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(_vm._s(_vm.$t("Back")))])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }